import styled, { css } from 'styled-components'
import { height, width } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'
import Slider from 'react-slick'

import { Flex, Box, Title, Text, Button } from 'Components/UI'
import { CONTENT_WIDTH, CONTENT_PADDING_X } from 'Constants/ids'

const SUBBANNER_IMAGE_WIDTH = 285

const borderRadiusCss = ({ roundedborder }) => css`
  border-radius: ${roundedborder
    ? themeGet('borderRadius.2')
    : themeGet('borderRadius.1')};
`

export const Container = styled.div`
  width: 100%;
`

export const Content = styled(Flex).attrs({
  flexDirection: 'column',
  alignItems: 'center',
  px: CONTENT_PADDING_X,
})`
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;
  max-width: ${CONTENT_WIDTH}px;
  margin: 0 auto;
  background: ${themeGet('colors.bg')};
`

export const Responsive = styled(Box)`
  width: 100%;
`

export const ResponsiveContent = styled(Box)`
  min-height: 10px;
  width: 100%;
`

const sizes = {
  desktop: {
    full: '760px',
    two_third: '500px',
    half: '392px',
    a_third: '250px',
  },
  mobile: {
    full: '392px',
    two_third: 'auto',
    half: 'auto',
    a_third: 'auto',
  },
}

export const Banner = styled(Flex).attrs({
  px: CONTENT_PADDING_X,
})`
  position: relative;
  background: ${props => `url(${props.bgurl})`} center center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  justify-content: center;
  height: ${({ size }) => sizes.desktop[size] || '760px'};
  min-height: 250px;
  width: 100%;

  @media screen and (max-width: ${themeGet('breakpoints.1')}) {
    height: ${({ size }) => sizes.mobile[size] || '392px'};
    min-height: ${({ size }) => (size === 'half' ? 'initial' : 'auto')};
    background-position: center right;
    flex-direction: column;
    display: flex;
  }
`

export const SubBannerContainer = styled(Flex).attrs({
  flexDirection: ['column'],
  alignItems: ['flex-start', 'flex-start', 'flex-start', 'space-between'],
})`
  width: calc(100% - ${SUBBANNER_IMAGE_WIDTH}px);

  @media screen and (max-width: ${themeGet('breakpoints.2')}) {
    width: 100%;
  }
`

export const SubBanner = styled(Flex).attrs({
  mt: 4,
  mb: [40, 40, 4],
  py: 24,
  px: 32,
  flexDirection: ['column-reverse', 'column-reverse', 'column-reverse', 'row'],
  justifyContent: 'space-between',
  alignItems: 'center',
})`
  width: 100%;
  background-color: ${themeGet('colors.white')};
  border: 1px solid ${themeGet('colors.lightGrey')};
  border-radius: ${themeGet('borderRadius.1')};
`

export const SubBannerImageHolder = styled(Flex).attrs({
  alignItems: ['center'],
  justifyContent: ['center'],
  ml: [0, 0, 0, 5],
})`
  min-height: 1px;
  height: 212px;
  width: ${SUBBANNER_IMAGE_WIDTH}px;
  min-width: ${SUBBANNER_IMAGE_WIDTH}px;
  background-image: linear-gradient(246deg, #e2e2e2, var(--white) 51%, #e2e2e2);
  @media screen and (max-width: ${themeGet('breakpoints.0')}) {
    height: 149px;
    width: 100%;
    min-width: 0;
  }
`

export const SubBannerImage = styled(Flex).attrs({
  mr: 0,
})`
  align-items: center;
  justify-content: center;
  max-height: 100%;
  width: 100%;
  height: 100%;
  background: url(${({ src }) => src}) no-repeat center center / cover
    content-box;
  @media screen and (max-width: ${themeGet('breakpoints.0')}) {
    background: url(${({ src }) => src}) no-repeat center center / contain
      content-box;
  }
`

export const SubBannerTitle = styled(Text).attrs({
  is: 'h3',
  fontSize: [18, 23, 27],
  mt: 1,
})`
  font-family: ${({ font }) => font};
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.4px;
`

export const SubBannerText = styled(Box).attrs({
  fontSize: [14, 15, 17],
  mt: [14, 16, 28],
})`
  line-height: 1.4;
  text-align: justify;
  color: ${themeGet('colors.boulder')};
  width: 100%;

  /* Override styling that's come in on the sub banner html */
  /* TODO: need to get rid of !important when backend clear inline styles */
  p,
  span {
    font-family: inherit !important;
    font-size: inherit !important;
  }

  p {
    margin-bottom: 1em !important;

    &:last-child {
      margin-bottom: 0 !important;
    }
  }

  a {
    color: ${({ anchor_color: anchorColor }) =>
      anchorColor || themeGet('colors.tealBlue')} !important;
    text-decoration: none !important;
  }
`

export const SearchContainer = styled(Flex)`
  align-items: flex-start;
  flex-direction: column;
  max-width: 1000px;
  width: 100%;
  @media screen and (max-width: ${themeGet('breakpoints.0')}) {
    align-self: flex-end;
    margin-bottom: 24px;
  }
`

export const BannerTitleHolder = styled(Flex).attrs({
  mb: 3,
  width: 1,
})`
  flex-wrap: wrap;
`

export const BannerTitleContent = styled(Flex).attrs({
  alignItems: 'flex-end',
})`
  position: relative;
  ${height};
  ${width};
`

export const BannerTitleBg = styled(Box).attrs({
  height: [23, 23, 36],
})`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  z-index: 0;

  /* hack for webkit to align banner text */
  &:not(*:root) {
    bottom: 0;
  }

  background-color: ${({ background: bg }) =>
    bg || themeGet('colors.tealBlue')};
`

export const BannerTitle = styled(Title).attrs({
  fontSize: [4, 4, 50],
  height: [31, 31, 51],
  is: 'h2',
  m: 0,
})`
  display: inline-block;
  color: ${themeGet('colors.white')};
  font-family: ${({ font }) => font};
  font-weight: 900;
  line-height: 1;
  text-align: left !important;
  text-shadow: #7f7f7f 2px 2px 3px;
  z-index: 1;
  ${height};
`

export const FeaturedMerchantsContainer = styled(Box).attrs({})``

export const FeaturedMerchantsTitle = styled(Text).attrs({
  fontSize: [3, 3, 32],
  mb: '40px',
})`
  color: ${themeGet('colors.black')};
  font-family: ${({ font }) => font};
  font-weight: bold;
  letter-spacing: 0.5px;
`

export const DesktopView = styled(Box).attrs({
  display: ['none', 'none', 'block', 'block'],
  width: 1,
})``

export const FeaturedMerchantsInnerContainer = styled(Flex)`
  display: grid;
  grid-gap: 32px;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
`

export const ButtonContainer = styled(Flex).attrs({
  justifyContent: 'center',
  mt: 4,
})``

export const ExploreButton = styled(Button)`
  background-color: ${themeGet('colors.white')};
  border: 2px solid
    ${props => props.color || themeGet('colors.tealBlue')(props)};

  color: ${props => props.color || themeGet('colors.tealBlue')(props)};
  text-transform: uppercase;

  ${borderRadiusCss}

  &:hover:enabled {
    background-color: ${props =>
      props.color || themeGet('colors.tealBlue')(props)};

    color: ${props => props.hovercolor || themeGet('colors.white')};
  }
`

export const FeaturedMerchantsMobileContainer = styled(Box).attrs({
  display: ['block', 'block', 'none', 'none'],
  mt: '-8px',
})`
  overflow: hidden;
  width: 100%;
`
export const FeaturedMerchantsMobileSlider = styled(Slider)``

export const LoaderHolder = styled(Box)`
  position: relative;
  height: 200px;
`

export const BannerTabsHolder = styled(Box).attrs({
  width: 1,
})`
  position: absolute;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);

  @media screen and (max-width: ${themeGet('breakpoints.1')}) {
    position: unset;
    top: unset;
    left: unset;
    margin-top: 24px;
    transform: none;
  }
`
